.emptyView-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .emptyView-wrap img {
  height:100vh;
    width: 100%;
  }
  